<template>
  <div>
    <PageIntroduce :title="'隐私声明管理'" :desc="'企业需依法收集和使用个人信息，在业务中对个人信息的采集、处理、分享等各项行为进行明确声明，包括Web网站，移动客户端，微信小程序等。'" :tips="'中国个人信息保护法 第六条 第十条 第十三条'"></PageIntroduce>
    <b-card>
      <div >
        <div class="filter-wrap flex-between">
          <div class="flex-start search-filter-wrap">
            <el-input
                v-model="dataForm.searchStr"
                width="180"
                placeholder="请输入内容"
                clearable
                @clear="handleFilter"
            />
            <el-button
                size="small"
                type="primary"
                icon="el-icon-search"
                @click="handleFilter"
            >
              搜索
            </el-button>
          </div>
          <div>
            <el-button @click="back">
              返 回
            </el-button>
          </div>
        </div>
        <div class="table-wrap">
          <el-table
              v-loading="dataListLoading"
              fit
              border
              highlight-current-row
              :data="dataList"
              stripe
              style="width: 100%"
          >
            <el-table-column
                prop="title"
                :show-overflow-tooltip="true"
                label="名称"
            >
              <template>
                {{ pageParams.title }}
              </template>
            </el-table-column>
            <el-table-column
                :show-overflow-tooltip="true"
                label="涉及业务"
            >
              <template>
                {{ pageParams.productNames }}
              </template>
            </el-table-column>
            <el-table-column
                prop="templateName"
                :show-overflow-tooltip="true"
                label="来源"
            />
            <el-table-column
                prop="area"
                :show-overflow-tooltip="true"
                label="地区"
            />
            <el-table-column
                prop="language"
                :show-overflow-tooltip="true"
                label="语言"
            />
            <el-table-column
                prop="effectiveTime"
                label="生效时间"
            >
              <template slot-scope="{row}">
                {{ row.effectiveTime | formatDate }}
              </template>
            </el-table-column>
            <el-table-column
                prop="versionNo"
                :show-overflow-tooltip="true"
                label="版本号"
            />
            <el-table-column
                prop="editStatus"
                :show-overflow-tooltip="true"
                label="编辑状态"
            >
              <template v-slot="scope">
                <el-tag type="info">{{editStatusOptions[scope.row.editStatus]}}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                prop="ExamineResult"
                label="审核状态"
            >
              <template v-slot="scope">
                <el-tooltip
                    :content="scope.row.examineMsg"
                    placement="top"
                >
                  <div v-if="scope.row.editStatus===3 && scope.row.examineStatus === 3">
                    <el-tag v-if="scope.row.examineMsg !== ''" type="info">{{examineResultOptions[scope.row.examineResult]}}</el-tag>
                    <el-tag v-else >{{examineResultOptions[scope.row.examineResult]}}</el-tag>
                  </div>
                  <div v-else-if="scope.row.editStatus===3 && scope.row.examineStatus === 1">
                    <el-tag >待审核</el-tag>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
                label="操作"
                width="160px"
            >
              <template v-slot="scope">
                <el-link
                    class="mr10"
                    :underline="false"
                    type="primary"
                    @click="addOrUpdateHandle(scope.row)"
                >
                  <el-tooltip
                      v-show="$hasPermission('statementUpdate')"
                      content="修改"
                      placement="top"
                      effect="light"
                  >
                    <i class="el-icon-edit" />
                  </el-tooltip>
                </el-link>

                <el-link
                    class="mr10"
                    v-show="$hasPermission('statementDelete')"
                    :underline="false"
                    type="primary"
                    @click="deleteHandle(scope.row.id)"
                >
                  <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                  >
                    <i class="el-icon-delete" />
                  </el-tooltip>
                </el-link>
                <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click="preview(scope.row.id)"
                >
                  <el-tooltip
                      content="预览"
                      placement="top"
                      effect="light"
                  >
                    <i class="el-icon-document" />
                  </el-tooltip>
                </el-link>
                <el-link
                    class="mr10"
                    v-show="scope.row.examinePerm && scope.row.examineStatus === 1"
                    :underline="false"
                    type="primary"
                    @click="examineHandle(scope.row.id)"
                >
                  <el-tooltip
                      content="审核"
                      placement="top"
                      effect="light"
                  >
                    <i class="el-icon-s-check" />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
              :current-page="dataForm.pageNo"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="dataForm.pageSize"
              :total="total"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="pageSizeChangeHandle"
              @current-change="pageCurrentChangeHandle">
          </el-pagination>

          <!-- 弹窗, 新增 / 修改 -->
          <edit-dialog v-if="editVisible" ref="editDialog" @refreshDataList="getList"></edit-dialog>
        </div>
      </div>
    </b-card>
    <b-card title="评估模板">
      <b-row
          v-if="templateList.length>0"
          class="match-height"
      >
        <b-col
            v-for="(item,index) in templateList"
            :key="index"
            xl="3"
            lg="3"
            md="3"
        >
          <div
              class="card-list mb-1"
              @click="goTemplatePage(item)"
          >
            <div class="card-list-header">
              <h4 class="card-list-title overflow-ellipsis px-5 ">
                {{ item.templateName }}
              </h4>
            </div>
            <div class="card-list-body">
              <el-tooltip
                  placement="top"
                  effect="light"
                  :content="item.description"
              >
                <span class="overflow-ellipsis card-list-abstract">{{ item.description }}</span>
              </el-tooltip>
            </div>
            <div class="text-right card-list-operate">
              <el-link
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="goTemplatePage(item)"
              >
              <span class="font-smaller-3 flex-between">
                <i class="el-icon-s-promotion" />
                <span class="f12">新建</span>
              </span>
              </el-link>
            </div>
          </div>
        </b-col>
      </b-row>
      <el-empty
          v-else
          description="暂无模板"
      />
    </b-card>
  </div>
</template>

<script>
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import EditDialog from './version-edit.vue'

export default {
  components: {
    EditDialog, PageIntroduce,
  },
  data() {
    return {
      editVisible: false,
      dataListLoading: false,
      dataList: [],
      total: 0,
      dataForm: {
        order: '', // 排序，asc／desc
        orderField: '', // 排序，字段
        pageNo: 1, // 当前页码
        pageSize: 10, // 每页数
        searchStr: '',
        productIdList: [],
        statementId: 0,
        area: '',
        language: '',
        status: undefined,
      },
      editStatusOptions: {
        1: '待编辑',
        2: '编辑中',
        3: '编辑完成',
      },
      examineResultOptions: {
        1: '通过',
        2: '驳回',
      },
      templateList: [],
      pageParams: {
        pageNo: 1,
        pageSize: 10,
        productNames: '',
        statementId: 0,
        title: '',
      },
    }
  },
  created() {
    this.getParams()
    this.getTemplateList()
    this.getList()
  },
  methods: {
    getParams() {
      this.pageParams = this.$route.query
      if (this.pageParams.statementId === undefined) {
        this.$message.error('参数错误。')
      }
      this.dataForm.statementId = Number(this.pageParams.statementId)
    },
    back() {
      this.$router.push({
        path: '/statement',
        query: this.pageParams,
      })
    },
    getList() {
      if (this.dataForm.statementId > 0) {
        this.dataListLoading = true
        this.$http.post('/privacy/statement/version/page', this.dataForm).then(({ data: res }) => {
          this.dataListLoading = false
          if (res.code !== 0) {
            this.dataList = []
            this.total = 0
            this.$message.error(res.msg)
          } else {
            this.dataList = res.data.list
            this.total = res.data.total
          }
        }).catch(() => {
          this.dataListLoading = false
        })
      }
    },
    getTemplateList() {
      this.$http.get('/privacy/statement/template').then(({ data: res }) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        } else {
          this.templateList = res.data
        }
      }).catch(() => {
      })
    },
    addOrUpdateHandle(row) {
      this.pageParams.haveFilter = false
      this.pageParams.id = row.id
      this.pageParams.templateCode = row.templateCode
      this.$router.push({
        path: '/statement-version-edit',
        query: this.pageParams,
      })
    },
    handleFilter() {
      this.dataForm.pageNo = 1
      this.getList()
    },
    deleteHandle(id) {
      this.$confirm('确定进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$http.delete(`/privacy/statement/version/delete?id=${id}`).then(({ data: res }) => {
          if (res.code !== 0) {
            this.$message.error(res.msg)
          } else {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 500,
              onClose: () => {
                this.getList()
              },
            })
          }
        }).catch(() => {
        })
      }).catch(() => {
      })
    },
    pageSizeChangeHandle(val) {
      this.pageNo = 1
      this.pageSize = val
      this.getList()
    },
    pageCurrentChangeHandle(val) {
      this.pageNo = val
      this.getList()
    },
    goTemplatePage(item) {
      const editPageParams = {
        pageNo: this.pageParams.pageNo,
        pageSize: this.pageParams.pageSize,
        productNames: this.pageParams.productNames,
        statementId: this.pageParams.statementId,
        title: this.pageParams.title,
        templateCode: item.code,
        haveFilter: false,
      }
      if (item.haveFilter) {
        editPageParams.haveFilter = item.haveFilter
        this.$router.push({
          path: '/statement-template',
          query: editPageParams,
        })
      } else {
        this.$router.push({
          path: '/statement-version-edit',
          query: editPageParams,
        })
      }
    },
    preview(id) {
      const routeUrl = this.$router.resolve({
        path: '/statement-read',
        query: { id },
      })
      window.open(routeUrl.href, '_blank')
    },
    examineHandle(id) {
      const examinePageParams = {
        pageNo: this.pageParams.pageNo,
        pageSize: this.pageParams.pageSize,
        productNames: this.pageParams.productNames,
        statementId: this.pageParams.statementId,
        title: this.pageParams.title,
        id,
      }
      this.$router.push({
        path: '/statement-examine',
        query: examinePageParams,
      })
    },
  },
}
</script>

<style>
.el-tooltip__popper{
  max-width: 20%;
}
</style>
